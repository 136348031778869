import { render, staticRenderFns } from "./succession.vue?vue&type=template&id=7db9334b&scoped=true&"
import script from "./succession.vue?vue&type=script&lang=js&"
export * from "./succession.vue?vue&type=script&lang=js&"
import style0 from "./succession.vue?vue&type=style&index=0&id=7db9334b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db9334b",
  null
  
)

export default component.exports